.wrapper {
  gap: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 100px 0;
  padding: 0 32px;

  @media (max-width: 986px) {
    bottom: -138px;
    width: 100%;
    padding: 0 23px;
  }

  @media (max-width: 586px) {
    margin-bottom: 0;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
}

.pre_sale {
  gap: 16px;
  z-index: 1;
}

.title {
  text-align: center;
  font-size: 35px;
  text-transform: uppercase;
  font-weight: 900;
  line-height: 1.5;
  margin-bottom: 10px;

  @media (max-width: 986px) {
    font-size: 25px;
  }
}
