.card {
  padding: 16px;
  border-radius: 16px;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
  max-width: 380px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &:hover {
    box-shadow: 0 0 #0000, 0 0 #0000, 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 1310px) {
    max-width: 100%;
  }

  @media (max-width: 680px) {
    max-width: 280px;
    margin: 0 auto;
  }

  &__photo {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    max-height: 275px;
    min-height: 275px;

    @media (max-width: 1200px) {
      min-height: unset;
      max-height: 240px;
    }

    @media (max-width: 986px) {
      max-height: 280px;
    }

    @media (max-width: 768px) {
      max-height: 250px;
    }

    @media (max-width: 568px) {
      max-height: 280px;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__content {
    gap: 8px;
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    color: #ff9900;

    @media (max-width: 680px) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__subtitle {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    color: #666;
    min-height: 58px;

    @media (max-width: 680px) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 24px;
      height: 24px;

      path {
        fill: #ff9900;
      }
    }
  }
}

.container {
  padding: 80px 0 100px;

  @media (max-width: 568px) {
    padding: 100px 0;
  }
}

.title {
  text-align: center;
  margin: 0 0 25px;
  font-size: 35px;
  text-transform: uppercase;
  color: #232f3f;
  font-weight: 900;
  line-height: 1.5;

  @media (max-width: 986px) {
    font-size: 25px;
    text-align: center;
  }
}

.wrapper {
  gap: 24px;
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 986px) {
    gap: 54px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 680px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
