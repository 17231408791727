.wrapper {
  padding-top: 60px;

  @media (max-width: 986px) {
    padding-top: 0;
  }
}

.title {
  width: 100%;
  text-align: center;
  font-size: 35px;
  text-transform: uppercase;
  color: #232f3f;
  font-weight: 900;
  line-height: 1.5;
  margin: 0 0 25px;

  @media (max-width: 986px) {
    font-size: 25px;
    text-align: center;
    margin-top: 40px;
  }
}

.subtitle {
  text-align: center;
  margin: 0 0 25px;
  font-size: 16px;
  color: #232f3f;
  margin-bottom: 26px;
  max-width: 80%;
  margin: 0 auto;
}

.feedback {
  gap: 40px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.statistics {
  width: 100%;
  margin: 60px 0 80px;
  position: relative;
  background: #f7fafc;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 16px;
  overflow: hidden;
  border: 2px solid #ff9900;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  div {
    &:nth-child(2n + 1) {
      border-right: 2px solid #ff9900;
    }

    &:nth-child(1),
    &:nth-child(2) {
      border-bottom: 2px solid #ff9900;
    }

    @media (max-width: 768px) {
      border-right: none !important;
      border-bottom: 2px solid #ff9900;

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.video {
  margin-bottom: 40px;

  &__title {
    text-align: center;
    font-size: 35px;
    text-transform: uppercase;
    color: #232f3f;
    font-weight: 900;
    line-height: 1.5;
    margin: 20px 0 40px;

    @media (max-width: 986px) {
      font-size: 25px;
      text-align: center;
    }
  }

  iframe {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    min-height: 70vh;
  }
}
