.banner {
  width: 100%;
  display: flex;
  max-width: 100%;
  align-items: center;
  background-color: #232f3f;
  height: calc(100vh - 70px);
  justify-content: space-between;
  position: relative;

  @media (max-width: 986px) {
    min-height: unset;
    height: auto;
    margin-top: 70px;
  }

  &__content {
    width: 100%;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    position: relative;

    @media (max-width: 986px) {
      min-height: 61vh;
    }

    @media (max-width: 568px) {
      height: 100%;
    }

    &__left {
      padding: 50px;
      z-index: 20;
      text-align: center;
      display: flex;
      flex-direction: column;

      @media (max-width: 986px) {
        width: 100%;
      }

      @media (max-width: 568px) {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      &_logo {
        width: 100%;
        max-width: 800px;
        display: block;
        margin: 0 auto;

        @media (max-width: 986px) {
          max-width: 68%;
        }
      }

      &_title {
        width: fit-content;
        font-family: inherit;
        margin: 10px auto;
        font-weight: 500;
        line-height: 1.1;
        color: white;
        font-size: 24px;
        border-bottom: 3px solid #ff9900;
        margin-top: 20px;
      }

      &_description {
        font-family: inherit;
        margin: 10px;
        font-weight: 500;
        line-height: 1.8;
        color: white;
        font-size: 18px;
        line-height: 26px;
      }

      &_register {
        background: rgba(255, 153, 0, 1);
        border-radius: 16px;
        max-width: 300px;
        width: 100%;
        padding: 18px 0;
        color: #fff;
        font-weight: 700;
        font-size: 18px;
        transition: all 0.3s ease-in-out;
        margin: 30px auto 0;

        &:hover {
          background: rgba(255, 153, 0, 0.8);
        }

        @media (max-width: 768px) {
          max-width: 400px;
        }
      }

      &_sponsor {
        border: 3px solid rgba(255, 153, 0, 1);
        border-radius: 16px;
        max-width: 300px;
        margin-top: 20px;
        width: 100%;
        padding: 18px 0;
        color: #fff;
        font-weight: 700;
        font-size: 18px;
        transition: all 0.3s ease-in-out;
        margin: 30px auto 0;

        &:hover {
          background: rgba(255, 153, 0, 1);
        }

        @media (max-width: 768px) {
          max-width: 400px;
        }
      }

      p {
        color: #fff;
      }

      &_donate {
        outline: 2px solid #ff9900;
        border-radius: 16px;
        max-width: 260px;
        width: 100%;
        padding: 18px 0;
        color: #fff;
        font-weight: 700;
        font-size: 18px;
        transition: all 0.3s ease-in-out;

        &:hover {
          background: #ff9900;
        }

        @media (max-width: 768px) {
          max-width: 400px;
        }
      }

      div {
        width: 100%;
        gap: 20px;
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 768px) {
          flex-direction: column;
        }
      }
    }

    &__right {
      width: 100%;
      height: calc(100vh - 71px);
      position: absolute;

      @media (max-width: 986px) {
        width: 100%;
        height: 100%;
      }

      &__bg {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba(35, 47, 63, 0.7);

        &_web {
          @media (max-width: 986px) {
            display: none;
          }
        }

        &_mobile {
          display: none;

          @media (max-width: 986px) {
            display: block;
          }
        }
      }

      video {
        width: 100%;
        height: 100%;
        max-width: 100%;
        object-fit: fill;
      }
    }
  }
}

.date {
  font-size: 30px;
  line-height: 1.8;
  color: #fff;

  &__wrapper {
    position: absolute;
    width: 0px;
    height: 0px;
    overflow: hidden;
  }

  a {
    color: #404d5b;
    text-decoration: none;
    outline: none;
  }

  section {
    position: relative;
    z-index: 1;
    overflow: hidden;
    backface-visibility: hidden;
  }

  section a {
    position: relative;
    display: inline-block;
    outline: none;
    color: #fff;
    vertical-align: bottom;
    text-decoration: none;
    white-space: nowrap;
    pointer-events: none;
    backface-visibility: hidden;
  }

  &__link a svg {
    position: absolute;
    top: 100%;
    left: 0;
    overflow: hidden;
    margin: 0;
    width: 100%;
    height: 20px;
    transition: stroke-dashoffset 0.3s ease-in-out;
    transform: translateY(-90%);
    fill: none;
    stroke: orange;
    stroke-width: 5;
    stroke-dasharray: 400px;
    stroke-dashoffset: 0px;
  }
}

.share {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-direction: row !important;

  &_item {
    width: 60px;
    height: 60px;

    &:first-child {
      width: 50px;
      height: 50px;

      svg {
        width: 50px;
        height: 50px;
      }
    }

    svg {
      width: 60px;
      height: 60px;

      path {
        fill: #fff;
      }
    }

    &_meet {
      path:last-child {
        fill: #232f3f;
      }
    }

    &_linkedin {
      width: 50px;
      height: 50px;

      svg {
        width: 50px;
        height: 50px;
      }

      rect {
        fill: #fff;
      }

      path {
        fill: #232f3f !important;
      }
    }
  }
}

.title {
  text-align: center;
  color: #ff9900;
  font-size: 28px;
  line-height: 28px;
  font-weight: bold;
  margin-top: 20px;
}
